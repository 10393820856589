import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { db } from " firebase";
import {
  collection,
  query,
  orderBy,
  getDocs,
  where,
  onSnapshot,
} from "firebase/firestore";
import { toast } from "react-toastify";

import { Collection } from "constants/Collection";
import { createPlayList } from "modules/battleground/features/battleGroundSlice";

const initialState = {
  loading: false,
  usersSongList: [],
  userProfile: "",
};

//getting list of artists
export const getProfile = createAsyncThunk(
  "profile/getAllUsers",
  async (payload, { dispatch }) => {
    try {
      let arr = [];
      const q = query(
        collection(db, Collection.USERS),
        where(payload.param, payload.condition, payload.target),
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        let obj = doc.data();
        obj["id"] = doc.id;
        arr.push(obj);
      });
      // eslint-disable-next-line
      const unsubscribe = onSnapshot(q, snapshot => {
        const newData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch(getProfile.fulfilled(newData));
      });
      return arr;
    } catch (e) {
      console.log(e);
    }
  },
);

//getting list of songs
export const getUserSongs = createAsyncThunk(
  "profile/getUserSongs",
  async (payload, { dispatch }) => {
    try {
      let arr = [];
      const q = query(
        collection(db, Collection.SONGS),
        where(payload.param, payload.condition, payload.target),
        orderBy("created", "desc"),
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        let obj = doc.data();
        obj["id"] = doc.id;
        arr.push(obj);
      });
      // eslint-disable-next-line
      const unsubscribe = onSnapshot(q, snapshot => {
        const newData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch(getUserSongs.fulfilled(newData));
      });
      let payload1 = {
        songList: arr,
      };
      dispatch(createPlayList(payload1));
      return arr;
    } catch (e) {
      console.log(e);
    }
  },
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    sortDataUsingDate: state => {
      let arr = !!state?.usersSongList ? [...state?.usersSongList] : [];
      arr?.sort((a, b) => b?.created?.seconds - a?.created?.seconds);
      state.usersSongList = arr;
    },
    sortDataByRating: state => {
      let arr = !!state?.usersSongList ? [...state?.usersSongList] : [];
      arr?.sort((a, b) => b?.likes - a?.likes);
      state.usersSongList = arr;
    },
  },
  extraReducers: builder => {
    // getAllDocs
    builder.addCase(getProfile.pending, state => {
      state.loading = true;
      state.userProfile = "";
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.length > 0) {
        state.userProfile = action?.payload[0];
      } else {
        state.userProfile = action?.payload;
      }
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.loading = false;
      toast.error(action?.error.message);
    });
    builder.addCase(getUserSongs.pending, state => {
      state.loading = true;
    });
    builder.addCase(getUserSongs.fulfilled, (state, action) => {
      state.loading = false;
      state.usersSongList = action.payload;
    });
    builder.addCase(getUserSongs.rejected, (state, action) => {
      state.loading = false;
      toast.error(action?.error.message);
    });
  },
});
export const { sortDataUsingDate, sortDataByRating } = profileSlice.actions;

export default profileSlice.reducer;
