import { auth } from " firebase";
import ReCAPTCHA from "react-google-recaptcha";
import { Formik } from "formik";
import { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updatePassword,
} from "firebase/auth";

import {
  DISPLAY_NAME_REQUIRED,
  EMAIL_REQUIRED,
  MIN_PASSWORD_LENGTH_REQUIRED,
  PASSWORD_REQUIRED,
  VALID_EMAIL,
} from "constants/errorMessage";
import {
  addGuestUser,
  addGuestUserIP,
  getGuestById,
  getUser,
  getUserByEmail,
  guestLogin,
  sendMail,
  signup,
  updateUser,
  voterLogin,
} from "modules/login/features/loginSlice";
import Cookies, { cookieKeys } from "services/cookies";
import { emailConstants } from "constants/EmailConstants";
import { handleSendMail } from "utils/handleSendMail";
import { EMAIL_VERIFICATION } from "constants/url_helpers";
import { reCaptchaSiteKey } from "constants/reCaptchaSiteKey";
import axios from "axios";

const userSchema = yup.object().shape({
  displayName: yup.string().required(DISPLAY_NAME_REQUIRED),
  email: yup.string().email(VALID_EMAIL).required(EMAIL_REQUIRED),
  password: yup
    .string()
    .min(6, MIN_PASSWORD_LENGTH_REQUIRED)
    .required(PASSWORD_REQUIRED),
});
const schema = yup.object().shape({
  // email: yup.string().email(VALID_EMAIL).required(EMAIL_REQUIRED),
});

const VoterModal = ({ closeModal, vote }) => {
  // states
  const [loading, setLoading] = useState(false);
  const [showReg, setShowReg] = useState(false);
  const [regToggle, setRegToggle] = useState(false);

  // dispatch
  const dispatch = useDispatch();
  // useRef
  const captchaRef = useRef(null);

  const generateRandomString = length => {
    // generate random string for temporary password
    const charset = "abcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset[randomIndex];
    }
    return result;
  };

  const handleGuestAccountCreation = email => {
    // account creation for guest
    const _pass = generateRandomString(6);
    createUserWithEmailAndPassword(auth, email, _pass).then(userCredentials => {
      const user = userCredentials?.user;
      const body = {
        email: email,
        temporaryPassword: _pass,
        role: "guest",
        id: user?.uid,
      };
      Cookies.set(cookieKeys.USER, body);
      let payload = {
        doc: user?.uid,
        body: body,
      };
      dispatch(addGuestUser(payload)).then(res => {
        setLoading(false);
        closeModal(false);
      });
    });
  };

  const getUserDetails = (email, displayName, voter = false, password) => {
    // fetch user details by email
    let payload = {
      email: email,
    };
    dispatch(getUserByEmail(payload))
      .then(res => {
        if (Array.isArray(res?.payload)) {
          if (res?.payload?.length > 0) {
            let user = res?.payload[0];
            if (user?.role === "voter") {
              // handleVoterLogin(user);
              toast.error("This email id is already used for voter");
              setLoading(false);
            } else if (user?.role === "guest") {
              if (voter) {
                let payload = {
                  doc: user?.id,
                  body: {
                    ...user,
                    displayName: displayName,
                    role: "voter",
                    slug: displayName
                      .replace(/[^a-zA-Z0-9]/g, "-")
                      .toLowerCase(),
                    xp: 0,
                  },
                };
                dispatch(updateUser(payload)).then(res => {
                  handleVoterLogin(user, password);
                });
              } else {
                Cookies.set(cookieKeys.USER, user);
                dispatch(guestLogin());
                setLoading(false);
                closeModal(false);
              }
            } else {
              toast.error(`This email is used for ${user?.role} account`);
              setLoading(false);
            }
          } else {
            handleGuestAccountCreation(email);
          }
        } else {
          console.log("Something went wrong");
        }
      })
      .catch(error => {
        toast.error("Something went wrong");
      });
  };

  const handleVoteWithoutRegisteration = async () => {
    try {
      await axios.get("https://api.ipify.org?format=json").then(res => {
        let payload = {
          ipAddress: res?.data?.ip,
        };
        dispatch(getGuestById(payload)).then(res => {
          let data = res?.payload;
          if (Array.isArray(data) && data.length > 0) {
            setTimeout(() => {
              vote();
              setLoading(false);
              closeModal(false);
            }, 1000);
          } else {
            dispatch(addGuestUserIP(payload)).then(res => {
              setTimeout(() => {
                vote();
                setLoading(false);
                closeModal(false);
              }, 1000);
            });
          }
        });
      });
    } catch (error) {
      console.error("Error fetching the IP address:", error);
    }
  };

  const handleReCaptchaSubmit = e => {
    // reCaptcha submit
    setLoading(true);
    const token = captchaRef.current.getValue();
    if (token) {
      if (regToggle) {
        setShowReg(true);
        setLoading(false);
      } else {
        // getUserDetails(e?.email);
        handleVoteWithoutRegisteration();
      }
    } else {
      toast.error("Please select the reCaptcha");
      setLoading(false);
    }
  };

  const handleVoterLogin = (values, password) => {
    // voter login
    let payload = {
      displayName: values?.displayName,
      email: values?.email,
    };
    dispatch(voterLogin(payload))
      .then(res => {
        if (Array.isArray(res?.payload) && res?.payload?.length > 0) {
          let userValues = res?.payload[0];
          signInWithEmailAndPassword(
            auth,
            userValues?.email,
            userValues?.temporaryPassword,
          )
            .then(res => {
              const user = res.user;
              Cookies.set(cookieKeys.TOKEN, user.accessToken);
              let payload = {
                doc: user.uid,
              };
              updatePassword(auth.currentUser, password)
                .then(() => {
                  console.log("update password");
                })
                .catch(error => {
                  console.log("error occured while updating password");
                });
              dispatch(getUser(payload)).then(res => {
                Cookies.set(cookieKeys.USER, res?.payload);
                closeModal(false);
                setLoading(false);
              });
            })
            .catch(errors => {
              if (errors?.code === "auth/user-not-found") {
                console.log("User not found");
              } else if (errors?.code === "auth/wrong-password") {
                console.log("The password is incorrect for voter login.");
              } else {
                toast.error("An error occurred while signing in the voter.");
              }
              setLoading(false);
            });
        } else {
          toast.error("Display name is incorrect");
          setLoading(false);
        }
      })
      .catch(e => {
        console.log(e);
        toast.error("An error occured while sign in ");
        setLoading(false);
      });
  };

  const handleRegisterVoter = values => {
    setLoading(true);
    const _pass = generateRandomString(6);
    createUserWithEmailAndPassword(auth, values?.email, values?.password)
      .then(userCredentials => {
        const user = userCredentials?.user;
        fetch(EMAIL_VERIFICATION, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: values?.email,
          }),
        })
          .then(res => res.json())
          .then(res => {
            let verificationLink = res?.link;

            let to = [];
            let b = {
              email: values?.email,
              name: values?.displayName,
            };
            to.push(b);
            emailConstants.VOTER_VERIFICATION_EMAIL = {
              ...emailConstants.VOTER_VERIFICATION_EMAIL,
              PARAGRAPH2: verificationLink,
            };
            let payload = handleSendMail(
              to,
              emailConstants.VOTER_VERIFICATION_EMAIL,
            );
            dispatch(sendMail(payload))
              .then(res => {
                toast.success("Please verify your email");
                const body = {
                  email: values?.email,
                  displayName: values?.displayName,
                  temporaryPassword: _pass,
                  role: "voter",
                  id: user?.uid,
                  slug: values.displayName
                    .replace(/[^a-zA-Z0-9]/g, "-")
                    .toLowerCase(),
                  xp: 0,
                };
                Cookies.set(cookieKeys.TOKEN, user?.accessToken);
                Cookies.set(cookieKeys.USER, body);
                let payload = {
                  doc: user?.uid,
                  body: body,
                };
                dispatch(signup(payload)).then(res => {
                  setLoading(false);
                  closeModal(false);
                });
              })
              .catch(error => {
                toast.error("Error while sending verification mail");
                console.log(error);
              });
          });
      })
      .catch(e => {
        // if the voter is already created
        getUserDetails(
          values?.email,
          values?.displayName,
          true,
          values?.password,
        );
      });
  };

  return (
    <div
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex  items-center justify-center min-h-full p-4 text-center sm:p-0">
          {!showReg ? (
            <Formik
              key={"form1"}
              initialValues={
                {
                  // email: "",
                }
              }
              onSubmit={values => {
                handleReCaptchaSubmit(values);
              }}
              validationSchema={schema}
            >
              {({ errors, touched, handleSubmit, handleChange, values }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="relative bg-vgraylight rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                    <div
                      onClick={() => closeModal(false)}
                      className="absolute top-0 right-0 m-2 h-6 w-6 flex items-center justify-center bg-vgraysuperdark header-medium rounded cursor-pointer hover:bg-vorange text-white"
                    >
                      x
                    </div>
                    <div>
                      <div className="mt-3 sm:mt-5">
                        <h3
                          id="modal-title"
                          className="text-lg leading-6 font-medium text-gray-200"
                        >
                          Are you Human ?
                        </h3>
                      </div>
                      <div className="mt-3 sm:mt-5">
                        <div className="w-full text-gray-400 mt-2">
                          <ReCAPTCHA
                            sitekey={reCaptchaSiteKey}
                            ref={captchaRef}
                          />
                        </div>
                      </div>
                      <div className="mt-3 sm:mt-2">
                        <div className="w-full text-gray-400 mt-2">
                          {/* <Form.Group controlId="validationFormikEmail">
                            <Form.Control
                              autoComplete="off"
                              type="email"
                              placeholder="Email Address"
                              className="text-gray-100 h-12 px-4 bg-vgraylight focus:bg-vgraylight disabled:bg-vgraylight focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0  rounded-r-md sm:text-sm  border border-gray-600 rounded"
                              name="email"
                              value={values?.email}
                              onChange={handleChange}
                              isInvalid={!!errors.email && touched.email}
                              disabled={loading}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors?.email}
                            </Form.Control.Feedback>
                          </Form.Group> */}
                        </div>
                      </div>
                      <div className="mt-3 sm:mt-2">
                        <div className="w-full text-gray-400 mt-2 inline-flex items-start">
                          <input
                            type="checkbox"
                            className="text-gray-100 bg-vgraylight block min-w-0 sm:text-sm border border-gray-600"
                            onChange={e => setRegToggle(e.target.checked)}
                          />
                          <label className="leading-tight text-sm ml-2 justify-start">
                            Register account to start earning rewards and
                            winning prizes
                          </label>
                        </div>
                      </div>
                    </div>
                    {loading ? (
                      <div className="mt-5 sm:mt-6 w-full flex justify-center ">
                        <label className="text-sm ">Please wait...</label>
                      </div>
                    ) : (
                      <div className="mt-5 sm:mt-6 w-full flex justify-between ">
                        <button
                          disabled={!regToggle}
                          type="submit"
                          className={`inline-flex justify-center  rounded-md border  shadow-sm px-4 py-2  text-base font-medium text-white ${
                            !regToggle
                              ? "bg-gray-500"
                              : "hover:bg-vorangedark bg-vorange border-vorange"
                          }    sm:text-sm`}
                        >
                          Collect XP
                        </button>
                        <button
                          type="submit"
                          className="inline-flex justify-center  rounded-md border border-vorange shadow-sm px-4 py-2 bg-vorange text-base font-medium text-white hover:bg-vorangedark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-vorange sm:text-sm"
                        >
                          Just Vote
                        </button>
                      </div>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <Formik
              key={"form2"}
              initialValues={{
                displayName: "",
                email: "",
                password: "",
              }}
              onSubmit={values => {
                handleRegisterVoter(values);
              }}
              validationSchema={userSchema}
            >
              {({ errors, touched, handleSubmit, handleChange, values }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="relative bg-vgraylight rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                    <div
                      onClick={() => closeModal(false)}
                      className="absolute top-0 right-0 m-2 h-6 w-6 flex items-center justify-center bg-vgraysuperdark header-medium rounded cursor-pointer hover:bg-vorange text-white"
                    >
                      x
                    </div>
                    <div>
                      <div className="mt-3 sm:mt-5">
                        <h3
                          id="modal-title"
                          className="text-lg leading-6 font-medium text-gray-200"
                        >
                          Submit your vote
                        </h3>
                        <div className="w-full text-gray-400 mt-2">
                          <Form.Group controlId="validationFormikDisplayName">
                            <Form.Control
                              autoComplete="off"
                              type="text"
                              placeholder="Display Name"
                              className="text-gray-100 h-12 px-4 bg-vgraylight focus:bg-vgraylight disabled:bg-vgraylight focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0  rounded-r-md sm:text-sm  border border-gray-600 rounded"
                              name="displayName"
                              value={values?.displayName}
                              onChange={handleChange}
                              isInvalid={
                                !!errors.displayName && touched.displayName
                              }
                              disabled={loading}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.displayName}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="mt-3 sm:mt-2">
                        <div className="w-full text-gray-400 mt-2">
                          <Form.Group controlId="validationFormikEmail">
                            <Form.Control
                              autoComplete="off"
                              type="email"
                              placeholder="Email Address"
                              className="text-gray-100 h-12 px-4 bg-vgraylight focus:bg-vgraylight disabled:bg-vgraylight focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0  rounded-r-md sm:text-sm  border border-gray-600 rounded"
                              name="email"
                              value={values?.email}
                              onChange={handleChange}
                              isInvalid={!!errors.email && touched.email}
                              disabled={loading}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors?.email}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="mt-3 sm:mt-2">
                        <div className="w-full text-gray-400 mt-2">
                          <Form.Group controlId="validationFormikPassword">
                            <Form.Control
                              autoComplete="off"
                              type="password"
                              placeholder="Password"
                              className="text-gray-100 h-12 px-4 bg-vgraylight focus:bg-vgraylight disabled:bg-vgraylight focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0  rounded-r-md sm:text-sm  border border-gray-600 rounded"
                              name="password"
                              value={values?.password}
                              onChange={handleChange}
                              isInvalid={!!errors.password && touched.password}
                              disabled={loading}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors?.password}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6">
                      <button
                        type="submit"
                        className="inline-flex justify-center w-full rounded-md border border-vorange shadow-sm px-4 py-2 bg-vorange text-base font-medium text-white hover:bg-vorangedark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-vorange sm:text-sm"
                      >
                        {loading ? "Please wait..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};
export default VoterModal;
