// login
export const EMAIL_REQUIRED = "Email is required.";
export const VALID_EMAIL = "Please enter a valid email address.";
export const PASSWORD_REQUIRED = "Password is required.";
export const MIN_PASSWORD_LENGTH_REQUIRED = "Password must be 6 characters long.";
export const DISPLAY_NAME_REQUIRED = "Display name is required.";
// songs upload
export const SONG_TITLE_REQUIRED = "Song title is required.";
export const COVER_ART_REQUIRED = "Cover art is required.";
export const SONG_REQUIRED = "mp3 is required.";
export const PROFILE_SONG_REQUIRED = "Song is required.";
// sponsor
export const COMPANY_NAME_REQUIRED = "Company name is required.";
export const CONTACT_NAME_REQUIRED = "Contact name is required.";
export const CONTACT_PHONE_REQUIRED = "Contact phone is required.";
export const VALID_CONTACT_PHONE = "Contact phone is not valid.";
export const CONTACT_EMAIL_REQUIRED = "Contact email is required.";
