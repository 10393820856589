const baseFunctionalUrl =
  "https://us-central1-soundhouse-20c66.cloudfunctions.net/widgets/";
const getUrl = endpoint => baseFunctionalUrl + endpoint;

export const EMAIL_VERIFICATION = getUrl("get-email-verification-link");
export const CREATE_SUBSCRIPTION = getUrl("stripe/create-subscription");
export const CANCEL_SUBSCRIPTION = getUrl("stripe/cancel-subscription");
export const GET_INVOICES = getUrl("stripe/get-invoices");
export const GET_SUBSCRIPTION_DETAILS = getUrl(
  "stripe/get-subscription-details",
);
export const GET_PLAN_DETAILS = getUrl("stripe/get-plan-details");
export const SCHEDULE_REMAINDER_EMAIL = getUrl("scheduleRemainderEmails");
export const CREATE_SESSION_LINK = getUrl("stripe/create-session-link");
export const RETRIEVE_SESSION = getUrl("stripe/retrieve-session");

/* I used the getUrl function to concatenate the base URL with the endpoint. */
