import { emailConstants } from "constants/EmailConstants";

export const handleSendMail = (to, mail, htmlVar = ``) => {
  let variablesArray = [];
  let personalizationArray = [];

  to?.map((item, key) => {
    let v = {
      email: item?.email,
      substitutions: [
        {
          var: "name",
          value: item?.name,
        },
        {
          var: "paragraph1",
          value: mail?.PARAGRAPH1,
        },

        {
          var: "paragraph2",
          value: mail?.PARAGRAPH2,
        },
        {
          var: "paragraph3",
          value: mail?.PARAGRAPH3,
        },
        {
          var: "wishes",
          value: mail?.WISHES,
        },
      ],
    };
    let p = {
      email: item?.email,
      data: {
        personalization_name: item?.name,
      },
    };
    variablesArray.push(v);
    personalizationArray.push(p);
    return {};
  });

  let payload = {
    to: to?.length > 1 ? [to[0]] : to,
    from: {
      email: "Info@soundhousemusic.com",
      name: "Sound House",
    },
    cc: [],
    bcc: [],
    subject: mail?.SUBJECT,
    html: htmlVar ? htmlVar : "",
    text: "",
    template_id: emailConstants.TEMPLATE_ID,
    variables: variablesArray,
    personalization: personalizationArray,
    tags: [],
    reply_to: {},
    send_at: "",
  };
  return payload;
};
