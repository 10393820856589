import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { db } from " firebase";
import { serverTimestamp } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";
import { Collection } from "constants/Collection";

const initialState = {};

// add sponsor
export const addSponsor = createAsyncThunk(
  "sponsor/addSponsor",
  async (payload, api) => {
    try {
      const docRef = await addDoc(collection(db, Collection.SPONSOR), {
        ...payload,
        created: serverTimestamp(),
      });
      payload["id"] = docRef.id;
      return payload;
    } catch (e) {
      console.log(e);
    }
  },
);

export const SponsorSlice = createSlice({
  name: "Sponsor",
  initialState,
  reducers: {},
  extraReducers: builder => {},
});

export default SponsorSlice.reducer;
