import { Link, useLocation } from "react-router-dom";
import { FaMicrophoneAlt, FaToolbox, FaTrophy } from "react-icons/fa";
import { FaGear } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Avatar from "react-avatar";
import { Tooltip as ReactTooltip } from "react-tooltip";

import routesConstants from "routes/routesConstants";
import defaultInitials from "utils/getInitials";

const SideBar = () => {
  // react redux
  const { user } = useSelector(state => state.auth);
  // router
  const location = useLocation();

  useEffect(() => {}, [user]);

  return (
    <div className="flex-shrink h-full z-50 ">
      <div className="fixed w-20 z-50 bg-vgraydark h-full lg:block hidden">
        <div className="w-full">
          <Link
            to={"/" + routesConstants.PROFILE}
            className="cursor-pointer w-full flex pt-6 justify-center relative"
          >
            {user?.avatar ? (
              <img
                alt=""
                src={user?.avatar}
                className="w-12 h-12 object-cover rounded-full"
                loading="lazy"
              ></img>
            ) : (
              <Avatar
                name={user?.displayName}
                className="w-12 h-12 object-cover rounded-full"
                email={user?.email}
                size="50"
                maxInitials={2}
                initials={defaultInitials(user?.displayName)}
                textSizeRatio={1}
              />
            )}
          </Link>
        </div>
        <div className="mt-10 ">
          <ul className="flex space-y-2 flex-wrap justify-center">
            <Link
              data-tooltip-id="battleGround"
              to={routesConstants.BATTLEGROUND}
              className={`w-14 h-14 transition duration-300 ease-in-out hover:bg-vorange hover:rounded-2xl cursor-pointer hover:text-white rounded shadow bg-vgraylight flex items-center justify-center text-gray-400 ${
                location.pathname.match(routesConstants.BATTLEGROUND)
                  ? "bg-vorange rounded-2xl text-white"
                  : ""
              }`}
            >
              <FaMicrophoneAlt className="text-xl" />
            </Link>
            <ReactTooltip
              id="battleGround"
              place="right"
              content="Battle Ground"
            />
            <Link
              data-tooltip-id="leaderBoard"
              to={routesConstants.LEADER_BOARD}
              className={`w-14 h-14 transition duration-300 ease-in-out hover:bg-vorange hover:rounded-2xl cursor-pointer hover:text-white rounded shadow bg-vgraylight flex items-center justify-center text-gray-400 ${
                location.pathname.match(routesConstants.LEADER_BOARD)
                  ? "bg-vorange rounded-2xl text-white"
                  : ""
              }`}
            >
              <FaTrophy className="text-xl" />
            </Link>
            <ReactTooltip
              id="leaderBoard"
              place="right"
              content="Leader Board"
            />
            <Link
              data-tooltip-id="Settings"
              to={routesConstants.SETTINGS}
              className={`w-14 h-14 transition duration-300 ease-in-out hover:bg-vorange hover:rounded-2xl cursor-pointer hover:text-white rounded shadow bg-vgraylight flex items-center justify-center text-gray-400 ${
                location.pathname.match(routesConstants.SETTINGS)
                  ? "bg-vorange rounded-2xl text-white"
                  : ""
              }`}
            >
              <FaGear className="text-xl" />
            </Link>
            <ReactTooltip id="Settings" place="right" content="Settings" />
            {user?.admin && (
              <>
                
                <Link
                  data-tooltip-id="admin"
                  to={routesConstants.DASHBOARD}
                  className={`w-14 h-14 transition duration-300 ease-in-out hover:bg-vorange hover:rounded-2xl cursor-pointer hover:text-white rounded shadow bg-vgraylight flex items-center justify-center text-gray-400 ${
                    location.pathname.match(routesConstants.DASHBOARD)
                      ? "bg-vorange rounded-2xl text-white"
                      : ""
                  }`}
                >
                  <FaToolbox className="text-xl" />
                </Link>
                <ReactTooltip
                  id="admin"
                  place="right"
                  content="Admin Settings"
                />
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default SideBar;
