import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { browserSessionPersistence, getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAKRVn_vx4Su5YQjJpGZCbt4w8b7He1JQI",
  authDomain: "soundhouse-20c66.firebaseapp.com",
  databaseURL: "https://soundhouse-20c66-default-rtdb.firebaseio.com",
  projectId: "soundhouse-20c66",
  storageBucket: "soundhouse-20c66.appspot.com",
  messagingSenderId: "323230689986",
  appId: "1:323230689986:web:e341ec2591323d88dca95b",
  measurementId: "G-BRJPN64MZN"
};

const app = initializeApp(firebaseConfig, {
  persistence: browserSessionPersistence,
  popupRedirectResolver: undefined,
});

export const db = getFirestore(app);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
