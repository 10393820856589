import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { db } from " firebase";
import { collection, query, getDocs } from "firebase/firestore";
import { toast } from "react-toastify";
import { Collection } from "constants/Collection";

const initialState = {
  loading: false,
  privacyPolicyList: [],
};

//getting list of artists
export const getAllData = createAsyncThunk(
  "privacyPolicy/getAllData",
  async (payload, api) => {
    try {
      let arr = [];
      const q = query(collection(db, Collection.PRIVACY_POLICY));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        let obj = doc.data();
        obj["id"] = doc.id;
        arr.push(obj);
      });
      return arr;
    } catch (e) {
      console.log(e);
    }
  },
);

export const privacyPolicySlice = createSlice({
  name: "privacyPolicy",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // getAllDocs
    builder.addCase(getAllData.pending, state => {
      state.loading = true;
      state.privacyPolicyList = [];
    });
    builder.addCase(getAllData.fulfilled, (state, action) => {
      state.loading = false;
      state.privacyPolicyList = action.payload;
    });
    builder.addCase(getAllData.rejected, (state, action) => {
      state.loading = false;
      toast.error(action?.error.message);
    });
  },
});

export default privacyPolicySlice.reducer;
