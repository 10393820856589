export let emailConstants = {
  TEMPLATE_ID: "vywj2lpjdqpl7oqz",
  SPONSOR_TEMPLATE_ID: "pxkjn417xv64z781",
  WELCOME_EMAIL: {
    SUBJECT: "Welcome to Sound House Music! 🎶",
    PARAGRAPH1:
      "We're thrilled to have you join Sound House Music, your gateway to music stardom! As an aspiring artist or music enthusiast, you're in for an incredible journey. Get ready to unleash your musical talent and connect with a vibrant community of fellow music lovers.",
    PARAGRAPH2:
      "In the coming days, you'll receive updates on exciting music battles, artist spotlights, and exclusive features. Stay tuned, because your time to shine is just around the corner. ",
    PARAGRAPH3:
      "If you have any questions or need assistance, don't hesitate to reach out. We're here to support your musical dreams every step of the way.",
    WISHES: "Let the music begin!",
  },
  BATTLE_ANNOUNCEMENT_EMAIL: {
    SUBJECT: "Battle Alert! Get Ready to Rock 🎤🔥",
    PARAGRAPH1:
      "We're thrilled to announce an upcoming music battle that's going to blow you away. Get your instruments and vocals ready because this is your chance to showcase your talent on the grand stage.",
    PARAGRAPH2: "", // battle details
    PARAGRAPH3:
      "Stay tuned for further updates and details on how to participate. This is your opportunity to shine! ",
    WISHES: "Rock on,",
  },
  BATTLE_REMINDER_EMAIL: {
    SUBJECT: "Tomorrow's the Day! 🎸",
    PARAGRAPH1:
      "Just a quick reminder that the epic music battle is happening now. It's your time to shine and show the world your musical prowess. Don't miss out on this incredible opportunity! ",
    PARAGRAPH2: "", // battle details
    PARAGRAPH3: "Get ready to make music history! ",
    WISHES: "Rock on,",
  },
  WINNER_ANNOUNCEMENT_EMAIL: {
    SUBJECT: "And the Winner Is... 🏆🥁 ",
    PARAGRAPH1: "", //winner name with text
    PARAGRAPH2: "", // winner details
    PARAGRAPH3:
      "Stay tuned for more battles, and keep shining in the world of music.",
    WISHES: "Best regards,",
  },
  VOTE_EMAIL: {
    SUBJECT: "🎉 You've Got a New Vote! 🎵",
    PARAGRAPH1:
      "We're thrilled to share some exciting news with you – your music has just received a new vote on Sound House Music! ",
    PARAGRAPH2:
      "Your talent is making waves, and this vote is a testament to the impact your music is having on our community. Every vote brings you one step closer to your musical dreams and recognition among your peers. ",
    PARAGRAPH3:
      "We believe in your potential and are here to support you every step of the way. Keep up the fantastic work, and get ready for even more votes and appreciation from your fans. ",
    WISHES:
      "Your journey in the world of music is just beginning, and we can't wait to see where it takes you. Congratulations on this vote, and may it be the first of many! ",
  },
  VOTER_VERIFICATION_EMAIL: {
    SUBJECT: "Confirm Your Email Address 📧",
    PARAGRAPH1:
      "Thank you for participating in Sound House Music! We just need a quick confirmation to ensure you're a real music enthusiast, not a bot. Click the link below to verify your email:",
    PARAGRAPH2: "", // verification link
    PARAGRAPH3:
      "By confirming your email, you'll stay in the loop with exciting music battles and artist spotlights. We look forward to sharing the magic of music with you. ",
    WISHES: "Rock on, ",
  },
  VOTER_WELCOME_EMAIL: {
    SUBJECT: "Welcome to the Sound House Music Community 🎵",
    PARAGRAPH1:
      "Welcome to the Sound House Music community! Your email address is verified, and you're now part of our vibrant music-loving family. ",
    PARAGRAPH2:
      "Get ready for thrilling music battles, jaw-dropping performances, and exclusive artist features. Even if you're not a musician, your support and votes make a significant impact on our platform.",
    PARAGRAPH3:
      "Stay tuned for upcoming battles and get ready to vote for your favourite artists! ",
    WISHES: "",
  },
  VOTER_BATTLE_EMAIL: {
    SUBJECT: "Get Ready to Vote in Our Next Battle 🗳️",
    PARAGRAPH1:
      "An exciting music battle is just around the corner, and your vote can make all the difference. Get ready to support your favourite artists and help them rise to the top!",
    PARAGRAPH2: "", // battle details ,
    PARAGRAPH3:
      "Your involvement as a music enthusiast is invaluable to us. Let's make music history together! ",
    WISHES: "Rock on, ",
  },
  VOTER_REMAINDER_EMAIL: {
    SUBJECT: "Don't Forget to Vote! 🎤",
    PARAGRAPH1:
      "The battle you've been waiting for is happening soon, and your vote can change the game. Make sure to mark your calendar and support your favourite artists. It's time to show your love for music! ",
    PARAGRAPH2: "", // battle details ,
    PARAGRAPH3: "We can't wait to see your favorite artists shine! ",
    WISHES: "",
  },
  SPONSOR_TO_EMAIL: "Info@soundhousemusic.com",
  SPONSOR_TO_NAME: "Sound House",
  SPONSOR_EMAIL: {
    SUBJECT: "New Sponsorship Inquiry",
    COMPANY_NAME: "",
    CONTACT_NAME: "",
    CONTACT_PHONE: "",
    CONTACT_EMAIL: "",
    MESSAGE: "",
  },
  ADMIN_EMAIL: "Info@soundhousemusic.com",
  // ADMIN_EMAIL:"aneesha.jenson@differenzsystem.com",
  ADMIN_NAME: "Sound House Team",
  ADMIN_NEW_USER_NOTIFICATION_EMAIL: {
    SUBJECT: "New User Alert: Welcome to Sound House Music! 🎉",
    PARAGRAPH1: "",
    PARAGRAPH2: "", //user details
    PARAGRAPH3: "",
    WISHES: "Best regards,",
  },
  ADMIN_NEW_PAID_USER_NOTIFICATION_EMAIL: {
    SUBJECT: "New Paid Member Alert: Welcome to Sound House Music! 🎉",
    PARAGRAPH1: "",
    PARAGRAPH2: "", //user details
    PARAGRAPH3: "",
    WISHES: "Best regards,",
  },
  ADMIN_NEW_BATTLE_NOTIFICATION_EMAIL: {
    SUBJECT: "New Battle Registration: Join in the Celebration!",
    PARAGRAPH1: "",
    PARAGRAPH2: "", //battle details
    PARAGRAPH3: "",
    WISHES: "Best regards,",
  },
  SONG_RANK_EMAIL: {
    SUBJECT: " Congratulations! You're in 2nd Place in the Music Battle! ",
    PARAGRAPH1:
      "We are excited to inform you that your song, song title is currently in 2nd place in our ongoing music battle! 🎉",
    PARAGRAPH2:
      "Your talent and hard work have clearly resonated with our audience, and your song has received a tremendous number of votes. This is an incredible achievement, and we wanted to take a moment to congratulate you on your success so far.",
    PARAGRAPH3:
      "Thank you for participating and sharing your music with us. We can't wait to see where you end up in the final rankings.",
    WISHES: "Best of luck, and keep up the fantastic work!",
  },
};
