const RegPopup = ({closePopup})=> {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded shadow-md w-full max-w-sm">
        <h2 className="text-2xl font-bold mb-4 text-gray-600">Thank You!</h2>
        <p className="mb-4 text-gray-700">You have successfully registered.</p>
        <button
          onClick={closePopup}
          className="w-full bg-vorange text-white p-2 rounded"
        >
          Ok
        </button>
      </div>
    </div>
  );
};
export default RegPopup;
