import { Suspense } from "react";
import { useSelector } from "react-redux";
import { Routes as ReactRouterRoutes, Route } from "react-router-dom";

import PageLoader from "components/PageLoader";
import routesConfig from "./routes.config";
import Layout from "layout";
import PageNotFound from "components/PageNotFound";

const Public = route => {
  // Logic for public routes
  const { Component } = route;

  return (
    <Suspense fallback={<PageLoader />}>
      <Component />
    </Suspense>
  );
};

const Private = route => {
  // Logic for Private routes
  const { Component } = route;

  return (
    <Suspense fallback={<PageLoader />}>
      <Component />
    </Suspense>
  );
};

const Common = route => {
  const { Component } = route;
  return (
    <Suspense fallback={<PageLoader />}>
      <Component />
    </Suspense>
  );
};

const createNestedRoutes = (routes, RouteType) => {
  return routes.map((route, i) => {
    if (!route.Component) {
      throw new Error("Component must be required....");
    }
    if (route.children) {
      return (
        <Route path={route.path} key={i} element={<RouteType {...route} />}>
          {createNestedRoutes(route.children, RouteType)}
        </Route>
      );
    } else {
      return (
        <Route
          key={i}
          index={route.index}
          path={route.path}
          element={<RouteType {...route} />}
        />
      );
    }
  });
};

const Routes = () => {
  const { isAuth, user } = useSelector(state => state.auth);
  const {
    private: privateRoutes,
    public: publicRoutes,
    common: commonRoutes,
  } = routesConfig;

  return (
    <ReactRouterRoutes>
      {isAuth && user ? (
        <>
          <Route path="/" element={<Layout />}>
            {createNestedRoutes(privateRoutes, Private)}
          </Route>
        </>
      ) : (
        <>
          <Route path="/" element={<Layout />}>
            {createNestedRoutes(publicRoutes, Public)}
          </Route>
        </>
      )}
      <Route path="/" element={<Layout />}>
        {createNestedRoutes(commonRoutes, Common)}
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </ReactRouterRoutes>
  );
};

export default Routes;
