export const Collection = {
  CATEGORIES: "categories",
  SONGS: "songs",
  USERS: "users",
  COMMENTS: "comments",
  BATTLES: "battles",
  NOTIFICATIONS: "notifications",
  AWARDS: "awards",
  LIKES: "likes",
  PRIVACY_POLICY: "privacyPolicy",
  EMAILS: "emails",
  LISTENS: "listens",
  VISIT: "visits",
  SPONSOR: "sponsor",
  XP: "xp",
  GUEST_USERS: "guestUsers",
};
