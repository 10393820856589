import cookies from "js-cookie";

const cookieConfig = {
  path: process.env.REACT_APP_COOKIE_PATH,
  domain: process.env.REACT_APP_COOKIE_DOMAIN,
  expires: +process.env.REACT_APP_COOKIE_EXPIRES,
};

export const cookieKeys = {
  TOKEN: "token",
  USER: "user",
  VISIT: "visit",
  SHARE: "share",
  GUEST_USER: "guestUser",
};

class Cookies {
  static get = key => {
    const value = cookies.get(key);
    return value && value !== "undefined" ? JSON.parse(value) : null;
  };

  static set = (key, value, config = cookieConfig) => {
    return cookies.set(key, JSON.stringify(value), config);
  };

  static remove = (key, config = cookieConfig) => {
    cookies.remove(key, config);
  };

  static clear = () => {
    Object.values(cookieKeys).forEach(key => {
      Cookies.remove(key);
    });
  };
}

export default Cookies;