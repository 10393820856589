import { hashValue } from "./HashUtils";

export const ttq = window.ttq || [];
console.log(ttq);
export const identifyUser = (email, phoneNumber, externalId) => {
  const data = {
    email,
    external_id: externalId,
  };
  if (phoneNumber) {
    data.phone_number = phoneNumber;
  }

  ttq.identify(data);
};

export const trackEvent = (eventType, eventData) => {
  ttq?.track(eventType, eventData);
};

export const handleIdentify = (email, phone = null, id) => {
  identifyUser(
    hashValue(email),
    phone ? hashValue(phone) : null,
    hashValue(id),
  );
};

export const handleSubscribe = (content) => {
  trackEvent("Subscribe", {
    value: content?.value,
    currency: "USD",
    contents: [
      {
        content_id: "0",
        content_type: "product",
        content_name: content?.name,
      },
    ],
  });
};

export const handleCancelSubscription = () => {
  trackEvent("Cancelled subscription", {
    value: 12.5,
    currency: "USD",
    contents: [
      {
        content_id: "0",
        content_type: "product",
        content_name: "Cancelled subscription",
      },
    ],
  });
};


export const handleViewContent = (content_id, content_type, content_name) => {
  trackEvent("ViewContent", {
    // value: 100,
    // currency: "USD",
    contents: [
      {
        content_id: content_id,
        content_type: content_type,
        content_name: content_name,
      },
    ],
  });
};

export const handleClickButton = (content_id, content_type, content_name) => {
  trackEvent("ClickButton", {
    // value: 100,
    // currency: "USD",
    contents: [
      {
        content_id: content_id,
        content_type: content_type,
        content_name: content_name,
      },
    ],
  });
};

export const handleAddPaymentInfo = () => {
  trackEvent("AddPaymentInfo", {
    value: 100,
    currency: "USD",
    contents: [
      {
        content_id: "1077218",
        content_type: "product",
        content_name: "shirt",
      },
    ],
  });
};

export const handleCompletePayment = () => {
  trackEvent("CompletePayment", {
    value: 100,
    currency: "USD",
    contents: [
      {
        content_id: "1077218",
        content_type: "product",
        content_name: "shirt",
      },
    ],
  });
};

export const handleCompleteRegistration = () => {
  trackEvent("CompleteRegistration", {
    value: 100,
    currency: "USD",
    contents: [
      {
        content_id: "1077218",
        content_type: "product",
        content_name: "shirt",
      },
    ],
    query: "SAVE10COUPON",
  });
};
