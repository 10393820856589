import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import RegPopup from "components/RegPopup";
import { getGuestById } from "modules/login/features/loginSlice";
import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useRecoilState } from "recoil";
import Routes from "routes";
import TikTokPixel from "utils/TikTokPixel";
import { showPopupAtom } from "utils/atom";

function App() {
  const stripePromise = loadStripe(
    "pk_live_51NXwmzByVwMfhHs8oJMCOL6iIn6eEq31wMAmSbUkFBUwZQKBZYFuzBC1w1ai9fdSs9BOkfXljAYK0MiI387eJMmp00i7u9BEjB",
  );
  const [showPopup, setShowPopup] = useRecoilState(showPopupAtom);
  const dispatch = useDispatch();
  const fetchGuestUser = async () => {
    try {
      await axios.get("https://api.ipify.org?format=json").then(res => {
        let payload = {
          ipAddress: res?.data?.ip,
        };
        dispatch(getGuestById(payload)).then(res => {});
      });
    } catch (error) {
      console.error("Error fetching the IP address:", error);
    }
  };
  useEffect(() => {
    fetchGuestUser();
    // eslint-disable-next-line
  }, []);
  return (
    <HelmetProvider>
      <TikTokPixel />
      <ToastContainer
        style={{ fontSize: "14px", zIndex: "10000000" }}
        autoClose={3000}
      />
      <Elements stripe={stripePromise}>
        <Routes />
      </Elements>
      {showPopup && <RegPopup closePopup={() => setShowPopup(false)} />}
    </HelmetProvider>
  );
}

export default App;
