import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  doc,
  getDoc,
  collection,
  query,
  orderBy,
  getDocs,
  where,
  onSnapshot,
} from "firebase/firestore";
import { toast } from "react-toastify";

import { db } from " firebase";
import { Collection } from "constants/Collection";

const initialState = {
  loading: false,
  battleList: [],
  filteredBattleList: [],
  player: "",
  playModal: false,
  playList: [],
  currentBattleIndex: 0,
  currentBattleItem: {},
};

//getting list of artists
export const getAllBattles = createAsyncThunk(
  "battleGround/getAllBattles",
  async (payload, { dispatch }) => {
    try {
      let arr = [];
      const q = query(
        collection(db, Collection.BATTLES),
        where("visible", "==", true),
        orderBy("sortOrder", "desc"),
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        let obj = doc.data();
        obj["id"] = doc.id;
        arr.push(obj);
      });
      // eslint-disable-next-line
      const unsubscribe = onSnapshot(q, snapshot => {
        const newData = snapshot?.docs?.map(doc => ({
          id: doc?.id,
          ...doc?.data(),
        }));
        dispatch(getAllBattles.fulfilled(newData));
      });
      return arr;
    } catch (e) {
      console.log(e);
    }
  },
);

//getting player details
export const createPlayList = createAsyncThunk(
  "battleGround/createPlayList",
  async (payload, { dispatch }) => {
    try {
      if (payload?.songList?.length > 0) {
        let payload1 = {
          song: payload?.songList[0],
        };
        dispatch(getPlayerDetails(payload1));
      }
      return payload.songList;
    } catch (e) {
      console.log(e);
    }
  },
);

//getting player details
export const getPlayerDetails = createAsyncThunk(
  "battleGround/getPlayerDetails",
  async (payload, api) => {
    try {
      let battleObj = "";
      if (payload?.song?.battleId) {
        const docRef = doc(db, Collection.BATTLES, payload?.song?.battleId);
        const docSnap = await getDoc(docRef);
        battleObj = docSnap.data();
      }

      const docRef1 = doc(db, Collection.USERS, payload?.song?.userId);
      const docSnap1 = await getDoc(docRef1);
      let artistObj = docSnap1.data();
      // eslint-disable-next-line
      const getArtistData = onSnapshot(docRef1, snapshot => {
        const newData = snapshot.data();
        getPlayerDetails.fulfilled({
          user: newData,
          song: payload?.song,
          battle: battleObj,
          play: payload?.play,
        });
      });

      return {
        user: artistObj,
        song: payload?.song,
        battle: battleObj,
        play: payload?.play,
      };
    } catch (e) {
      console.log(e);
    }
  },
);

export const battleGroundSlice = createSlice({
  name: "battleGround",
  initialState,
  reducers: {
    filterBattle: (state, { payload }) => {
      let arr = [...state.filteredBattleList];
      let newArray = [];
      let currentDate = new Date();
      arr?.map(battle => {
        let startDate = new Date(battle?.startDate?.seconds * 1000);
        let endDate = new Date(battle?.endDate?.seconds * 1000);
        switch (payload?.battleStatus) {
          case 1:
            if (startDate > currentDate) {
              newArray.push(battle);
            }
            break;
          case 2:
            if (currentDate >= startDate && currentDate <= endDate) {
              newArray.push(battle);
            }
            break;
          case 3:
            if (currentDate > endDate) {
              newArray.push(battle);
            }
            break;
          default:
            newArray.push(battle);
            break;
        }
        state.battleList=newArray
      });
    },
    closePlayerModal: state => {
      state.playModal = false;
      state.player = "";
    },
    changeBattle: (state, action) => {
      state.currentBattleIndex = action.payload || 0;
    },
  },
  extraReducers: builder => {
    // getAllDocs
    builder.addCase(getAllBattles.pending, state => {
      state.loading = true;
      state.battleList = [];
    });
    builder.addCase(getAllBattles.fulfilled, (state, action) => {
      state.loading = false;
      state.battleList = action.payload;
      state.filteredBattleList = action.payload;
    });
    builder.addCase(getAllBattles.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getPlayerDetails.pending, state => {
      state.loading = true;
      state.player = "";
    });
    builder.addCase(getPlayerDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.playModal = action.payload.play || state.playModal ? true : false;
      state.player = action.payload;
    });
    builder.addCase(getPlayerDetails.rejected, (state, action) => {
      state.loading = false;
      toast.error(action?.error.message);
    });
    builder.addCase(createPlayList.pending, state => {
      state.loading = true;
      state.playList = [];
    });
    builder.addCase(createPlayList.fulfilled, (state, action) => {
      state.loading = false;
      state.playList = action.payload;
    });
    builder.addCase(createPlayList.rejected, (state, action) => {
      state.loading = false;
      toast.error(action?.error.message);
    });
  },
});

export const { closePlayerModal, changeBattle,filterBattle } = battleGroundSlice.actions;

export default battleGroundSlice.reducer;
