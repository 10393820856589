import { Outlet, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useMemo } from "react";

import Header from "./components/Header";
import SideBar from "./components/SideBar";
import Player from "components/Player";

const Layout = () => {
  const { isAuth, user } = useSelector(
    state => state.auth,
    (prev, next) => prev.isAuth === next.isAuth && prev.user === next.user,
  );
  const playModal = useSelector(
    state => state.battleGround.playModal,
    (prev, next) => prev === next,
  );
  const dispatch = useDispatch();

  const location = useLocation();
  const isAuthUserAndNotRoot = useMemo(
    () => isAuth && user && location?.pathname !== "/",
    [isAuth, user, location],
  );
  
  const element = document.getElementById("content");
  if (element) {
    element.scrollIntoView();
  }

  return (
    <div
      id="app bg-vgray"
      className="flex w-full h-screen overflow-auto bg-vgraylight"
    >
      {playModal && <Player dispatch={dispatch} />}
      <Header />
      <div id="content"></div>
      <div className="bg-vgraylight  text-white w-full flex bg-vgray ">
        {isAuthUserAndNotRoot && <SideBar />}
        {isAuthUserAndNotRoot ? (
          <div className={`w-full lg:ml-20 mt-14`}>
            <Outlet />
            {playModal && <div className="flex h-32 md:h-16"></div>}
          </div>
        ) : (
          <div className={`w-full mt-14`}>
            <Outlet />
            {playModal && <div className="flex h-16"></div>}
          </div>
        )}
      </div>
    </div>
  );
};
export default Layout;
