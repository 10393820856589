import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { db } from " firebase";
import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  where,
  onSnapshot,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { Collection } from "constants/Collection";
import { createPlayList } from "modules/battleground/features/battleGroundSlice";

const initialState = {
  loading: false,
  usersList: [],
  leaderBoardSongsList: [],
};

//getting list of artists
export const getAllUsers = createAsyncThunk(
  "leaderBoard/getAllUsers",
  async (payload, { dispatch }) => {
    try {
      let arr = [];
      const q = query(
        collection(db, Collection.USERS),
        where("role", "==", "creator"),
        orderBy("xp", "desc"),

        limit(50),
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        let obj = doc.data();
        obj["id"] = doc.id;
        arr.push(obj);
      });
      // eslint-disable-next-line
      const unsubscribe = onSnapshot(q, snapshot => {
        arr = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch(getAllUsers.fulfilled(arr));
      });
      return arr;
    } catch (e) {
      console.log(e);
    }
  },
);

//getting list of songs
export const getAllSongs = createAsyncThunk(
  "leaderBoard/getAllSongs",
  async (payload, { dispatch }) => {
    try {
      let arr = [];
      const q = query(
        collection(db, Collection.SONGS),
        orderBy("likes", "desc"),
        limit(50),
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        let obj = doc.data();
        obj["id"] = doc.id;
        arr.push(obj);
      });
      // eslint-disable-next-line
      const unsubscribe = onSnapshot(q, snapshot => {
        arr = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch(getAllSongs.fulfilled(arr));
      });
      let payload1 = {
        songList: arr,
      };
      dispatch(createPlayList(payload1));
      return arr;
    } catch (e) {
      console.log(e);
    }
  },
);

export const leaderboardSlice = createSlice({
  name: "battleGround",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // getAllDocs
    builder.addCase(getAllUsers.pending, state => {
      state.loading = true;
      state.usersList = [];
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.usersList = action.payload;
    });
    builder.addCase(getAllUsers.rejected, (state, action) => {
      state.loading = false;
      toast.error(action?.error.message);
    });
    builder.addCase(getAllSongs.pending, state => {
      state.loading = true;
      state.leaderBoardSongsList = [];
    });
    builder.addCase(getAllSongs.fulfilled, (state, action) => {
      state.loading = false;
      state.leaderBoardSongsList = action.payload;
    });
    builder.addCase(getAllSongs.rejected, (state, action) => {
      state.loading = false;
      toast.error(action?.error.message);
    });
  },
});

export default leaderboardSlice.reducer;
