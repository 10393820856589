import { Link } from "react-router-dom";
import routesConstants from "routes/routesConstants";

const PageNotFound = () => {
  return (
    <div className=" bg-vgraydark min-h-screen flex flex-grow items-center justify-center ">
      <div className="bg-vgraylight rounded-lg p-8 text-center text-gray-500 shadow-xl">
        <h1 className="mb-4 text-4xl font-bold">404</h1>
        <p className="text-gray-600">
          Oops! The page you are looking for could not be found.
        </p>
        <Link
          to={routesConstants.HOME}
          className="mt-4 inline-block rounded bg-blue-500 px-4 py-2 font-semibold text-white hover:bg-blue-600"
        >
          Go back to Home
        </Link>
      </div>
    </div>
  );
};
export default PageNotFound;