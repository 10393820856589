import { combineReducers, configureStore } from "@reduxjs/toolkit";
import battleGroundSlice from "modules/battleground/features/battleGroundSlice";

import battleSlice from "modules/battle/features/battleSlice";
import discoverSlice from "modules/discover/features/discoverSlice";
import loginSlice from "modules/login/features/loginSlice";
import leaderBoardSlice from "modules/leaderBoard/features/leaderBoardSlice";
import profileSlice from "modules/profile/features/profileSlice";
import adminUserSlice from "modules/adminUserRole/features/adminUserSlice";
import privacyPolicySlice from "modules/privacyPolicy/features/privacyPolicySlice";
import sponsorSlice from "modules/sponsor/features/sponsorSlice";

const cReducers = combineReducers({
  auth: loginSlice,
  discover: discoverSlice,
  battle: battleSlice,
  battleGround: battleGroundSlice,
  leaderBoard: leaderBoardSlice,
  profile: profileSlice,
  adminUser: adminUserSlice,
  privacyPolicy: privacyPolicySlice,
  sponsor: sponsorSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "reset") {
    return cReducers(undefined, action);
  }
  return cReducers(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
